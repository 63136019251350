import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import MdToHTML from '../components/mdToHTML';
import ContentPage from '../components/contentPage';
import moment from 'moment';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';

import '../assets/scss/pages/_JobPage.scss';


class JobsDescription extends React.PureComponent {
  constructor(args) {
    super(args);

    this.htmlParserConf = {
      hr: { className: 'g-brd-gray-light-v4' },
      h1: { className: 'h3' },
      h2: { className: 'h4' },
      h3: { className: 'h5' },
      h4: { className: 'h6' },
      h5: { className: 'h6' },
      h6: { className: 'h6' }
    };
  }

  render() {
    const { seo, jobTitle, jobType, date, location, htmlAst } = this.props.pageContext;
    return (
      <Layout data={this.props.pageContext.data} location={this.props.location}>
        <SEO {...seo} />


        <StaticQuery
          query={graphql`
            query HeaderImgQuery {
              file(relativePath: { eq: "header-bg-medis.png" }) {
                  childImageSharp {
                      fixed(width: 2500 height: 793 quality: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                      }
                  }
              }
            }
          `}
          render={data => (
            <ContentPage
              preHeader={'Become a part of Impect'}
              header={jobTitle}
              headerImage={data.file.childImageSharp.fixed}
              backLink={`/${seo.lang}/jobs`}
              align="left"
              content={(
                <Container className="job-wrapper">
                  <Row>
                    <Col md={4} className="float-right float-sm-left">
                      <Row>
                        <Col md={12} className="info-section">
                          <i className="far fa-clock"/>
                          <span>Type</span>
                          {jobType}
                        </Col>
                        <Col md={12} className="info-section">
                          <i className="far fa-calendar-alt"/>
                          <span>Start</span>
                          {moment(date).format('L')}
                        </Col>
                        <Col md={12} className="info-section">
                          <i className="fas fa-map-marker-alt impect-text-blue"/>
                          <span>Location</span>
                          <div className="impect-text-blue">{location}</div>
                        </Col>
                        <Col md={12}>
                          <h4 className="mb-4">
                            <FormattedMessage id="applicationTo"/>:
                          </h4>
                          Siegburger Str. 149-151
                          <br/>
                          50679 <FormattedMessage id="cologne"/>
                          <br/>
                          <FormattedMessage id="germany"/>

                          <Button
                            block
                            tag="a"
                            href="mailto:inof@impect.com"
                            className="mt-4"
                          >
                            <FormattedMessage id="applyNew"/>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <MdToHTML
                            htmlAst={htmlAst}
                            parserConf={this.htmlParserConf}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col md={9}>
                          <h5 className="mt-0 mb-0">
                            <FormattedHTMLMessage id="applicationInfo"/>
                          </h5>
                        </Col>
                        <Col md={3}>
                          <Button
                            block
                            tag="a"
                            href="mailto:inof@impect.com"
                            className="mt-4"
                          >
                            <FormattedMessage id="applyNew"/>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              )}
            />
          )}
        />
      </Layout>
    );
  }
}

export default JobsDescription;

