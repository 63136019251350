import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MdToHMTL extends Component {
  constructor(props) {
    super(props);

  }

  makeTag(tagName, properties, children, parserConf) {
    const Tag = tagName;
    const content = [];

    for ( let i = 0; i < children.length; i++ ) {
      const row = children[i];
      if (row.type === 'text') {
        content.push(row.value);
      } else {
        content.push(this.makeTag(row.tagName, row.properties, row.children, parserConf));
      }
    }

    let classes = '';
    if (parserConf[Tag] && parserConf[Tag].className) {
      classes = parserConf[Tag].className;
    }

    if (['hr', 'br'].includes(tagName)) {
      return ( <Tag className={classes} /> );
    }
    return ( <Tag className={classes}>{content}</Tag> );
  }

  parsingContent(htmlAst, parserConf) {
    const content = [];
    if (!htmlAst.children) return null;

    for ( let i = 0; i < htmlAst.children.length; i++ ) {
      const row = htmlAst.children[i];
      if (row.tagName && row.type === 'element') {

        const Tag = row.tagName;
        let classes = '';
        if (parserConf[Tag] && parserConf[Tag].className) {
          classes = parserConf[Tag].className;
        }

        content.push(this.makeTag(Tag, row.properties, row.children, parserConf));
      }
    }

    return content;
  }

  render() {
    const { htmlAst, parserConf } = this.props;
    return this.parsingContent(htmlAst, parserConf);
  }
}

MdToHMTL.defaultProps = {
  parserConf: null,
  htmlAst: null
};

MdToHMTL.propTypes = {
  parserConf: PropTypes.object,
  htmlAst: PropTypes.object
};

export default MdToHMTL;

